<template>
  <div v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="voicedetails">
      <div class="one">
        <img src="../../assets/users.png" />
        <div>萝卜猎手官方</div>
      </div>
      <div class="voiceimg">
        <img src="../../assets/voicedetails.png" />
      </div>
      <div class="voicemsg">
        <div>
          <div>申请公司</div>
          <div>{{ allmsg.invoice_title }}</div>
        </div>
        <div>
          <div>发票类型</div>
          <div>{{ allmsg.invoice_type == 2 ? "专用发票" : "普通发票" }}</div>
        </div>
        <div>
          <div>抬头类型</div>
          <div>{{ allmsg.invoice_type2 == "2" ? "企业单位" : "个人单位" }}</div>
        </div>
        <div>
          <div>公司税号</div>
          <div>{{ allmsg.invoice_num }}</div>
        </div>
        <div>
          <div>发票金额</div>
          <div>{{ allmsg.money }}</div>
        </div>
        <div>
          <div>申请日期</div>
          <div>{{ allmsg.add_time }}</div>
        </div>
        <div v-show="allmsg.invoice_type == 2">
          <div>公司地址</div>
          <div>{{ allmsg.enterprise_addr }}</div>
        </div>
        <div v-show="allmsg.invoice_type == 2">
          <div>公司电话</div>
          <div>{{ allmsg.enterprise_tel }}</div>
        </div>
        <div v-show="allmsg.invoice_type == 2">
          <div>开户行</div>
          <div>{{ allmsg.bank }}</div>
        </div>
        <div v-show="allmsg.invoice_type == 2">
          <div>银行账号</div>
          <div>{{ allmsg.bank_card_id }}</div>
        </div>
        <div>
          <div>发票备注</div>
          <div>{{ allmsg.note }}</div>
        </div>
        <div>
          <div>开票类目</div>
          <div>{{ allmsg.billing_cate }}</div>
        </div>
        <div>
          <div>收件人姓名</div>
          <div>{{ allmsg.addressee }}</div>
        </div>
        <div>
          <div>收件人手机</div>
          <div>{{ allmsg.address_phone }}</div>
        </div>
        <div>
          <div>收件人地址</div>
          <div>{{ allmsg.address }}</div>
        </div>
        <div>
          <div>快递费用</div>
          <div>{{ allmsg.mail_cost == "1" ? "到付" : "已付" }}</div>
        </div>
        <div class="voicetype">
          <div>邮寄方式</div>
          <div class="allyouji">
            <div
              v-show="allmsg.mail_type == 1"
              class="youji youji1"
              id="selectdiv"
            >
              <img src="../../assets/sr.png" />
              <div>顺丰快递</div>
            </div>
            <div
              v-show="allmsg.mail_type == 2"
              class="youji youji2"
              id="selectdiv"
            >
              <img src="../../assets/ems.png" />
              <div>EMS快递</div>
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="beizhu">备注</div>
          <div>暂无</div>
        </div>
        <div>
          <div>电子邮箱</div>
          <div>471701002@qq.com</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      msg: "申请详情",
      allmsg: "",
    };
  },
  mounted() {
    //查询发票详细信息
    this.getvoice(this.$route.query.id);
  },
  methods: {
    /**
     * 根据id查询发票详情
     */
    getvoice(id) {
      this.$http
        .post("/firm/v1/userinfo/billable_detail", {
          reqType: "userinfo",
          id: id,
        })
        .then((res) => {
          this.allmsg = JSON.parse(res.data).data;
        });
    },
  },
};
</script>
<style scoped>
#selectdiv {
  border: 1px solid #ff2b00;
  background-color: #fff6ee;
  color: #222222;
}
.allyouji {
  display: flex;
  align-items: center;
}
.youji {
  display: flex;
  align-items: center;
  color: #5b6574;
  border: 1px solid #616a78;
  min-width: 2rem;
  justify-content: center;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.youji1 img {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.1rem;
}
.youji2 img {
  width: 0.67rem;
  height: 0.19rem;
  margin-right: 0.1rem;
}
.voicemsg {
  font-size: 0.28rem;
  line-height: 0.35rem;
}
.voicemsg > div {
  display: flex;
  margin-bottom: 0.3rem;
}
.voicemsg > div > div:first-child {
  margin-right: 0.31rem;
  color: #7d7d7d;
}
.beizhu::after {
  content: "哈哈";
  visibility: hidden;
}
.voicedetails {
  background-color: #ffffff;
  margin: 0.3rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
}
.one {
  display: flex;
  padding-bottom: 0.15rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d9d9d9;
}
.one img {
  width: 0.46rem;
  height: 0.4rem;
  margin-right: 0.21rem;
  align-items: center;
}
.one div {
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.voiceimg {
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0.21rem;
}
.voiceimg img {
  width: 1.21rem;
  height: 0.86rem;
}
</style>